module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"855de8c8e3279e4a0583ea56554ff9ce"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com","https://cdn.bancointer.com.br"],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css?family=Inter:300,400,500,700,900&display=swap"},{"name":"Sora","file":"https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap"},{"name":"Citrina","file":"https://cdn.bancointer.com.br/inter-assets/latest/css/citrina.css"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
